import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import AuthenticatedRoutes from './authenticated.routes';
import UnauthenticatedRoutes from './unauthenticated.routes';

const Routes: React.FC = () => {
  const { signed } = useAuth();

  return signed ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />;
};

export default Routes;

import { CredentialResponse } from '@react-oauth/google';
import React, { createContext, useState, useEffect, useContext } from 'react';
import client from '../services/client';
import { isExpired } from '../utils';

interface AuthContextData {
  signed: boolean;
  auth?: IAuthResponse;
  isLoading: boolean;
  googleLogin: (credentialResponse: CredentialResponse) => Promise<void>;
  logout(): void;
}

export interface IAuthResponse {
  accessToken: string;
  refreshToken: string;
  accessTokenExpiresAt: Date;
  refreshTokenExpiresAt: Date;
  user: {
    name: string;
    lastName: string;
    pictureUrl: string;
    email: string;
    role: string;
  };
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<any> = ({ children }) => {
  const [auth, setAuth] = useState<IAuthResponse>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const authAsyncStorage = sessionStorage.getItem('@PrecoCombustivel:auth');

      if (!authAsyncStorage) {
        setIsLoading(false);
        return;
      }

      let parsedAuth = JSON.parse(authAsyncStorage) as IAuthResponse;

      if (isExpired(parsedAuth.refreshTokenExpiresAt)) {
        setIsLoading(false);
        return;
      }

      if (isExpired(parsedAuth.accessTokenExpiresAt)) {
        try {
          const refreshResponse = await client.get<IAuthResponse>('/auth/refresh', {
            headers: {
              Authorization: `Bearer ${parsedAuth.refreshToken}`,
            },
          });

          parsedAuth = refreshResponse.data;
        } catch (e) {
          setIsLoading(false);
          return;
        }
      }

      client.defaults.headers.common['Authorization'] = `Bearer ${parsedAuth.accessToken}`;
      setAuth(parsedAuth);
      setIsLoading(false);
    })();
  }, []);

  async function googleLogin({ credential }: CredentialResponse) {
    const apiResponse = await client.get<IAuthResponse>('auth/google/id-token', {
      headers: { Authorization: `Bearer ${credential}` },
    });

    if (apiResponse.status === 200 && apiResponse.data.user.role === 'ADMIN') {
      client.defaults.headers.common['Authorization'] = `Bearer ${apiResponse.data.accessToken}`;
      setAuth(apiResponse.data);
      sessionStorage.setItem('@PrecoCombustivel:auth', JSON.stringify(apiResponse.data));
    }
  }

  function logout() {
    setAuth(undefined);
    sessionStorage.removeItem('@PrecoCombustivel:auth');
  }

  return (
    <AuthContext.Provider value={{ signed: Boolean(auth), auth: auth, isLoading, googleLogin, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export enum FuelType {
  GASOLINA,
  GASOLINA_ADITIVADA,
  ETANOL,
  DIESEL,
  GNV,
}

export const getFuelTypeLabel = (type: FuelType) => {
  const labelByType: Record<FuelType, string> = {
    [FuelType.GASOLINA]: 'Gasolina',
    [FuelType.GASOLINA_ADITIVADA]: 'Gasolina aditivada',
    [FuelType.ETANOL]: 'Etanol',
    [FuelType.DIESEL]: 'Diesel',
    [FuelType.GNV]: 'GNV',
  };

  return labelByType[type];
};

import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import client from './client';

export interface Request {
  file: File;
}

export type Response = {};

const useUploadFuelFile = () => {
  const { isLoading, error, data, mutate, reset } = useMutation<AxiosResponse<Response>, any, Request>(
    ['uploadFuelFile'],
    (uploadFuel) => {
      const formData = new FormData();
      formData.append('file', uploadFuel.file);

      return client.post<Response>(`/fuel/upload-file`, formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      });
    }
  );

  return { isLoading, error, success: data?.status === 201, mutate, reset };
};

export default useUploadFuelFile;

import React, { useCallback } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import useUploadFuelFile from '../../services/useUploadFuelFile';
import useProcessGeolocation from '../../services/useProcessGeolocation';

const UploadFuelFile: React.FC = () => {
  const { isLoading, mutate, success, reset, error } = useUploadFuelFile();
  const {
    isLoadingProcessGeolocation,
    processGeolocationMutate,
    processGeolocationSuccess,
    processGeolocationReset,
    processGeolocationError,
  } = useProcessGeolocation();

  const onDrop = useCallback((acceptedFiles: any) => {
    mutate({ file: acceptedFiles[0] });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Container maxWidth={false}>
      <Box marginBottom={2}>
        <Typography variant="h4">Upload de preços</Typography>
      </Box>

      <Grid container>
        <Grid item xs={8}>
          <Box
            {...getRootProps()}
            style={{
              width: '100%',
              height: 300,
              border: '2px solid black',
              borderRadius: 10,
              borderStyle: 'dashed',
              cursor: 'pointer',
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <input {...getInputProps()} />
            <CloudUploadIcon style={{ fontSize: 72 }} />
          </Box>
        </Grid>
      </Grid>

      <Box marginY={3}>
        <Typography variant="h4" marginBottom={3}>
          Geolocalização
        </Typography>
        <Button onClick={processGeolocationMutate} variant="contained">
          Atualizar geolocalização
        </Button>
      </Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || isLoadingProcessGeolocation}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={reset}
      >
        <Alert severity="success" variant="filled" onClose={reset}>
          <AlertTitle>Sucesso</AlertTitle>
          Arquivo enviado com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={reset}
      >
        <Alert severity="error" variant="filled" onClose={reset}>
          <AlertTitle>Erro</AlertTitle>
          Erro ao enviar arquivo: {error?.response?.data?.message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={processGeolocationSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={processGeolocationReset}
      >
        <Alert severity="success" variant="filled" onClose={processGeolocationReset}>
          <AlertTitle>Sucesso</AlertTitle>
          Processo de geolocalização iniciado com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!processGeolocationError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={processGeolocationError}
      >
        <Alert severity="error" variant="filled" onClose={processGeolocationReset}>
          <AlertTitle>Erro</AlertTitle>
          Erro ao processar geolocalização: {processGeolocationError?.response?.data?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UploadFuelFile;

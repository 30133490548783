import { AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import client from './client';

export type NotifyUserRequest = {
  title: string;
  body: string;
  data?: object;
};

export type NotifyUserResponse = {};

const useNotifyUser = () => {
  const { isLoading, error, status, reset, mutateAsync } = useMutation<
    AxiosResponse<NotifyUserResponse>,
    any,
    NotifyUserRequest
  >(['notifyUser'], (notification) => client.post('notification/notifyUser', notification).then((res) => res.data));

  const success = status === 'success';

  return { isLoading, error, success, notifyUser: mutateAsync, reset };
};

export default useNotifyUser;

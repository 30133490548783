import React, { useState } from 'react';
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import useNotifyAllUsers from '../../services/useNotifyAllUsers';
import useNotifyUser from '../../services/useNotifyUser';

const Notification: React.FC = () => {
  const [notificationType, setNotificationType] = useState<'all' | 'unique'>('unique');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [notificationToken, setNotificationToken] = useState<string>('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [data, setData] = useState('');
  const [dataError, setDataError] = useState(false);
  const {
    isLoading: isLoadingNotifyAllUsers,
    success: notifyAllUsersSuccess,
    error: notifyAllUsersError,
    reset: notifyAllUsersReset,
    notifyAllUsers,
  } = useNotifyAllUsers();

  const {
    isLoading: isLoadingNotifyUser,
    success: notifyUserSuccess,
    error: notifyUserError,
    reset: notifyUserReset,
    notifyUser,
  } = useNotifyUser();

  const isLoading = notificationType === 'unique' ? isLoadingNotifyUser : isLoadingNotifyAllUsers;
  const success = notificationType === 'unique' ? notifyUserSuccess : notifyAllUsersSuccess;
  const error = notificationType === 'unique' ? notifyUserError : notifyAllUsersError;
  const reset = notificationType === 'unique' ? notifyUserReset : notifyAllUsersReset;
  const notify = notificationType === 'unique' ? notifyUser : notifyAllUsers;

  const handleClose = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirm = async () => {
    setShowConfirmationModal(false);

    try {
      await notify({
        title: title,
        body: body,
        notificationToken: notificationToken,
        data: JSON.parse(data),
      });

      setNotificationToken('');
      setTitle('');
      setBody('');
      setData('');
      setShowConfirmationModal(false);
    } catch (e) {}
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    try {
      if (data) {
        JSON.parse(data);
      }
      setShowConfirmationModal(true);
    } catch (e) {
      setDataError(true);
    }
  };

  const notificationLabel = notificationType === 'all' ? 'notificações' : 'notificação';
  const notificationPreposition = notificationType === 'all' ? 'as' : 'a';

  return (
    <Grid container direction="column" px={3}>
      <Typography variant="h4" mb={2}>
        Notificações
      </Typography>

      <Grid spacing={2} direction="column" xs={12} lg={6}>
        <form onSubmit={handleSubmit}>
          <RadioGroup
            aria-label="notification-option"
            name="notification-option"
            value={notificationType}
            onChange={(e) => setNotificationType(e.target.value as any)}
          >
            <FormControlLabel value="unique" control={<Radio />} label="Notificar usuário único" />
            <FormControlLabel value="all" control={<Radio />} label="Notificar todos os usuários" />
          </RadioGroup>

          <TextField
            required
            label="Titulo"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
          />

          <TextField
            required
            label="Corpo"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
          />

          <TextField
            label="Dados"
            value={data}
            onChange={(e) => setData(e.target.value)}
            variant="outlined"
            margin="normal"
            fullWidth
          />

          {notificationType === 'unique' && (
            <TextField
              required
              label="Token de notificação"
              value={notificationToken}
              onChange={(e) => setNotificationToken(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
            />
          )}

          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Notificar
            </Button>
          </Box>

          <Dialog open={showConfirmationModal} onClose={handleClose}>
            <DialogTitle>Enviar {notificationLabel}?</DialogTitle>
            <DialogContent>
              <p>
                Tem certeza que você deseja enviar {notificationPreposition} {notificationLabel}?
              </p>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleConfirm} color="primary" variant="contained">
                Enviar
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Grid>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={reset}
      >
        <Alert severity="success" variant="filled" onClose={reset}>
          <AlertTitle>Sucesso</AlertTitle>
          {notificationLabel} enviada com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={reset}
      >
        <Alert severity="error" variant="filled" onClose={reset}>
          <AlertTitle>Erro</AlertTitle>
          Erro ao enviar {notificationPreposition} {notificationLabel}: {error?.response?.data?.message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={dataError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setDataError(false)}
      >
        <Alert severity="error" variant="filled" onClose={reset}>
          <AlertTitle>JSON Inválido</AlertTitle>O conteúdo do JSON é inválido
        </Alert>
      </Snackbar>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default Notification;

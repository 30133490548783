import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FuelType, getFuelTypeLabel } from '../../services/enums';
import useGetFuelSuggestion from '../../services/useGetFuelSuggestion';
import useValidateFuelSuggestion from '../../services/useValidateFuelSuggestion';
import { formatBrazilianDate, parseBrazilianDate } from '../../utils/date';
import { formatDistance } from '../../utils/distance';
import { currencyMask, currencyToFloat, formatPrice } from '../../utils';

const FuelSuggestion: React.FC = () => {
  const { fuelSuggestion, isLoading, refetch } = useGetFuelSuggestion();
  const { fuelSuggestionSuccess, fuelSuggestionError, mutateAsync, reset } = useValidateFuelSuggestion();
  const [referenceDate, setReferenceDate] = useState<Date | undefined>(undefined);
  const [rejectedReason, setRejectedReason] = useState<string | undefined>(undefined);
  const [prices, setPrices] = useState<Record<FuelType, string>>({} as any);

  useEffect(() => {
    if (fuelSuggestion) {
      setPrices(
        fuelSuggestion.prices.reduce(
          (acc, curr) => ({ ...acc, [FuelType[curr.type]]: formatPrice(curr.price) }),
          {} as any
        ) || ({} as any)
      );
    }
  }, [fuelSuggestion]);

  const resetForm = () => {
    setReferenceDate(undefined);
    setRejectedReason(undefined);
    setPrices({} as any);
  };

  const hasChanges =
    fuelSuggestion?.prices?.reduce(
      (acc, curr) => acc || currencyToFloat(prices[curr.type as FuelType]) !== curr.price,
      false
    ) || false;

  const handleApprove = async () => {
    if (fuelSuggestion) {
      const fuelList = Object.keys(prices).map((k) => {
        const key = FuelType[k as any] as unknown as FuelType;
        return { fuelType: key, price: currencyToFloat(prices[k as unknown as FuelType]) };
      });

      await mutateAsync({
        approved: true,
        data: fuelList,
        fuelSuggestionId: fuelSuggestion?.id,
        referenceDate: referenceDate,
      });

      resetForm();

      setTimeout(async () => await refetch(), 1000);
    }
  };

  const handleReprove = async () => {
    if (fuelSuggestion) {
      resetForm();
      await mutateAsync({
        approved: false,
        data: [],
        fuelSuggestionId: fuelSuggestion?.id!,
        rejectedReason: rejectedReason,
      });
      await refetch();
    }
  };

  const handleChange = (event: any) => {
    setPrices((prev: any) => ({
      ...prev,
      [FuelType[event.target.name]]: currencyMask(event.target.value),
    }));
  };

  return (
    <Container maxWidth={false}>
      <Box>
        <Typography variant="h4">Sugestão de preço</Typography>
      </Box>

      {fuelSuggestion && (
        <>
          <Typography variant="body1">{fuelSuggestion.totalSuggestions} Sugestões restantes</Typography>

          {isLoading && <CircularProgress />}

          <Grid container mt={2}>
            <Grid item md={12} lg={6}>
              {fuelSuggestion.totalSuggestions > 0 && (
                <a href={fuelSuggestion.imageUrl} target="_blank" rel="noreferrer">
                  <img
                    width="100%"
                    height="800px"
                    style={{ objectFit: 'contain', cursor: 'pointer' }}
                    src={`${fuelSuggestion.imageUrl}`}
                    alt="Sugestão de preço"
                  />
                </a>
              )}
            </Grid>

            <Grid item md={12} lg={6} paddingX={2}>
              <Box>
                <List dense>
                  <ListItem>
                    <Typography fontWeight="bold">Criado por:</Typography>
                    <Typography>{fuelSuggestion.createdBy}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Criado por usuário:</Typography>
                    <Typography>{fuelSuggestion.createdByUsername}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Criado em:</Typography>
                    <Typography>{formatBrazilianDate(fuelSuggestion.createdAt)}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Data de referência:</Typography>
                    <Typography>{formatBrazilianDate(fuelSuggestion.referenceDate)}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Distância do posto:</Typography>
                    <Typography>{formatDistance(fuelSuggestion.distanceFromGasStation)}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Nome:</Typography>
                    <Typography>{fuelSuggestion.gasStation.name}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Razão Social:</Typography>
                    <Typography>{fuelSuggestion.gasStation.corporateName}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">CNPJ:</Typography>
                    <Typography>{fuelSuggestion.gasStation.CNPJ}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Bandeira:</Typography>
                    <Typography>{fuelSuggestion.gasStation.flag}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Estado:</Typography>
                    <Typography>{fuelSuggestion.gasStation.state}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Cidade:</Typography>
                    <Typography>{fuelSuggestion.gasStation.city}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Bairro:</Typography>
                    <Typography>{fuelSuggestion.gasStation.district}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Endereço:</Typography>
                    <Typography>{fuelSuggestion.gasStation.address}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Número:</Typography>
                    <Typography>{fuelSuggestion.gasStation.residentialNumber}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Complemento:</Typography>
                    <Typography>{fuelSuggestion.gasStation.complement}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">CEP:</Typography>
                    <Typography>{fuelSuggestion.gasStation.zipCode}</Typography>
                  </ListItem>
                </List>
              </Box>

              <form style={{ width: '100%' }}>
                <Paper
                  style={{
                    width: '100%',
                    display: 'grid',
                    gridRowGap: '20px',
                    padding: '20px',
                  }}
                >
                  <TextField
                    id="referenceDate"
                    name="referenceDate"
                    label="Data de referência"
                    onChange={(evt) => setReferenceDate(parseBrazilianDate(evt.target.value))}
                    variant="outlined"
                  />

                  <TextField
                    id="GASOLINA"
                    name="GASOLINA"
                    label={getFuelTypeLabel(FuelType.GASOLINA)}
                    onChange={handleChange}
                    value={prices[FuelType.GASOLINA]}
                    variant="outlined"
                  />

                  <TextField
                    id="GASOLINA_ADITIVADA"
                    name="GASOLINA_ADITIVADA"
                    label={getFuelTypeLabel(FuelType.GASOLINA_ADITIVADA)}
                    onChange={handleChange}
                    value={prices[FuelType.GASOLINA_ADITIVADA]}
                    variant="outlined"
                  />

                  <TextField
                    id="ETANOL"
                    name="ETANOL"
                    label={getFuelTypeLabel(FuelType.ETANOL)}
                    onChange={handleChange}
                    value={prices[FuelType.ETANOL]}
                    variant="outlined"
                  />

                  <TextField
                    id="DIESEL"
                    name="DIESEL"
                    label={getFuelTypeLabel(FuelType.DIESEL)}
                    onChange={handleChange}
                    value={prices[FuelType.DIESEL]}
                    variant="outlined"
                  />

                  <TextField
                    id="GNV"
                    name="GNV"
                    label={getFuelTypeLabel(FuelType.GNV)}
                    onChange={handleChange}
                    value={prices[FuelType.GNV]}
                    variant="outlined"
                  />

                  <TextField
                    id="rejectedReason"
                    name="rejectedReason"
                    label="Motivo da reprovação"
                    onChange={(evt) => setRejectedReason(evt.target.value)}
                    value={rejectedReason}
                    variant="outlined"
                  />

                  <Button disabled={!hasChanges} onClick={handleApprove} name="approve" variant="contained">
                    Aprovar
                  </Button>
                  <Button onClick={handleReprove} name="reprove" variant="contained" color="error">
                    Reprovar
                  </Button>
                </Paper>
              </form>
            </Grid>
          </Grid>
        </>
      )}

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={fuelSuggestionSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={reset}
      >
        <Alert severity="success" variant="filled" onClose={reset}>
          <AlertTitle>Sucesso</AlertTitle>
          Sugestão de preço validada com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!fuelSuggestionError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={reset}
      >
        <Alert severity="error" variant="filled" onClose={reset}>
          <AlertTitle>Erro</AlertTitle>
          Erro ao validar sugestão de preço: {fuelSuggestionError?.response?.data?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FuelSuggestion;

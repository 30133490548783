import React from 'react';
import { Box, Button, ListItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

type NavItemProps = {
  href: string;
  icon: any;
  title: string;
};

const NavItem: React.FC<NavItemProps> = ({ href, icon, title }) => {
  const { pathname } = useLocation();
  const active = href ? pathname === href : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
      }}
    >
      <Link to={href} style={{ width: '100%', textDecoration: 'none' }}>
        <Button
          startIcon={icon}
          disableRipple
          sx={
            {
              backgroundColor: active && 'rgba(255,255,255, 0.08)',
              borderRadius: 1,
              color: active ? 'secondary.main' : 'neutral.300',
              fontWeight: active && 'fontWeightBold',
              justifyContent: 'flex-start',
              px: 3,
              textAlign: 'left',
              textTransform: 'none',
              width: '100%',
              '& .MuiButton-startIcon': {
                color: active ? 'secondary.main' : 'neutral.400',
              },
              '&:hover': {
                backgroundColor: 'rgba(255,255,255, 0.08)',
              },
            } as any
          }
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
        </Button>
      </Link>
    </ListItem>
  );
};

export default NavItem;

import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import client from './client';

export interface Request {
  search: string;
  skip: number;
  take: number;
}

export type ResponseItem = {
  id: string;
  name: string;
  corporateName: string;
  flagUrl: string;
  flag: string;
  complement: string;
  residentialNumber: string;
  address: string;
  district: string;
  zipCode: string;
  city: string;
  state: string;
  CNPJ: string;
  coordinateLatitude: number;
  coordinateLongitude: number;
  createdAt: Date;
};

export type Response = {
  list: ResponseItem[];
  total: number;
};

const useSearchGasStation = (
  search: string | undefined,
  page: number,
  rowsPerPage: number,
  orderBy: keyof ResponseItem,
  order: 'asc' | 'desc'
) => {
  const skip = page * rowsPerPage;
  const take = rowsPerPage;

  const { isLoading, error, data } = useQuery<AxiosResponse<Response>>(
    ['searchGasStation', search, skip, rowsPerPage, orderBy, order],
    () => client.get<Response>(`/gas-station/search`, { params: { search, skip, take, orderBy, order } })
  );

  return {
    isLoadingSearch: isLoading,
    searchError: error,
    searchData: data?.data || { list: [], total: 0 },
  };
};

export default useSearchGasStation;

import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import client from './client';
import { FuelType } from './enums';

export type Response = {};

export type Request = {
  fuelSuggestionId: string;
  approved: boolean;
  referenceDate?: Date;
  rejectedReason?: string;
  data: {
    price: number;
    fuelType: FuelType;
  }[];
};

const useValidateFuelSuggestion = () => {
  const { isLoading, error, data, mutateAsync, reset } = useMutation<AxiosResponse<Response>, any, Request>(
    (validateFuelSuggestion) => client.post('fuel-suggestion/validate', validateFuelSuggestion)
  );

  return {
    isLoadingFuelSuggestion: isLoading,
    fuelSuggestionError: error,
    fuelSuggestionSuccess: data?.status === 201,
    mutateAsync,
    reset,
  };
};

export default useValidateFuelSuggestion;

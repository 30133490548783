import { ResponseItem } from '../../../services/useSearchGasStation';

interface HeadCell {
  id: keyof ResponseItem;
  label: string;
}

export const headCells: readonly HeadCell[] = [
  { id: 'id', label: 'ID' },
  { id: 'corporateName', label: 'Razão Social' },
  { id: 'name', label: 'Nome Fantasia' },
  { id: 'CNPJ', label: 'CNPJ' },
  { id: 'zipCode', label: 'CEP' },
  { id: 'address', label: 'Endereço' },
  { id: 'residentialNumber', label: 'Número' },
  { id: 'district', label: 'Bairro' },
  { id: 'city', label: 'Cidade' },
  { id: 'state', label: 'Estado' },
  { id: 'complement', label: 'Complemento' },
  { id: 'coordinateLatitude', label: 'Latitude' },
  { id: 'coordinateLongitude', label: 'Longitude' },
  { id: 'createdAt', label: 'Data de Criação' },
];

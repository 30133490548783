import dayjs from 'dayjs';

const SAFE_MARGIN = 3; //seconds

export const isExpired = (date: Date): boolean => {
  return dayjs().add(SAFE_MARGIN, 's').isAfter(date);
};

export const formatPrice = (price: number | string): string => {
  if (!price) {
    return 'R$ 0,00';
  }

  return price.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

export function currencyMask(value: string) {
  let numericValue = value.replace(/[^0-9]/g, '');

  if (numericValue.length > 4) {
    numericValue = numericValue.slice(0, 4);
  }

  let floatValue = parseFloat(numericValue) / 100;

  if (isNaN(floatValue)) {
    floatValue = 0;
  }

  return formatPrice(floatValue);
}

export function currencyToFloat(value: string) {
  if (value === undefined || value === null) return 0;
  let normalized = value;
  normalized = normalized.replace('R$', '').trim();
  normalized = normalized.replace(/\./g, '');
  normalized = normalized.replace(',', '.');
  return parseFloat(normalized);
}
